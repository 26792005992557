"use client";

import { useState, useMemo } from "react";
import { Button } from "./ui/button";
import { Card, CardContent, CardHeader } from "./ui/card";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";

import { CreditCard, Download, Wallet } from "lucide-react";
import { useWallets } from "../hooks/wallets/use-wallets";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { ScrollArea } from "./ui/scroll-area";
import { images } from "../assets/images";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";
import { useChat } from "../hooks/use-chat";
import { Skeleton } from "./ui/skeleton";
import { selectPreferredAgentThunk } from "../redux/slices/gptThunk";
import { XMarkIcon } from "@heroicons/react/24/solid";

const Image = ({
  image,
  defaultImage,
}: {
  image: any;
  defaultImage: string;
}) => {
  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = defaultImage;
  };

  return (
    <div className="flex flex-col items-center justify-center gap-2 w-12">
      <img
        src={image?.logo_uri}
        alt={image?.name || "Crypto Logo"}
        onError={handleImageError}
        className="h-10 w-10 rounded-full"
      />
    </div>
  );
};

export default function WalletPopUp({
  setShowBalances,
}: {
  setShowBalances: (show: boolean) => void;
}) {
  // const [isAiWalletsOpen, setIsAiWalletsOpen] = useState(false);
  const { appendMessage, superAgent, setMessages, loading } = useChat();
  const dispatch = useAppDispatch();

  const { addresses } = useWallets();
  const [selectedAddress, setSelectedAddress] = useState("all");
  const { balances, isLoading } = useAppSelector((state) => state.user);
  const { preferredAgent } = useAppSelector((state) => state.gpt);

  const filteredAndSortedBalances = useMemo(() => {
    if (!balances) return [];
    const balancesCopy = [...balances];

    const uniqueBalances = balancesCopy.filter(
      (balance, index, self) =>
        index ===
        self.findIndex(
          (b) =>
            b.address === balance.address &&
            b.wallet_address === balance.wallet_address &&
            b.chain === balance.chain
        )
    );

    // If "all" is selected, return all unique balances
    if (selectedAddress === "all") {
      return uniqueBalances.sort((a, b) => {
        if (b.usd_amount === undefined) return -1;
        if (a.usd_amount === undefined) return 1;
        return b.usd_amount - a.usd_amount;
      });
    }

    // Filter by the selected wallet address - exact match required
    return uniqueBalances
      .filter((balance) => balance.wallet_address === selectedAddress)
      .sort((a, b) => {
        if (b.usd_amount === undefined) return -1;
        if (a.usd_amount === undefined) return 1;
        return b.usd_amount - a.usd_amount;
      });
  }, [balances, selectedAddress]);

  // Handle address selection
  const handleAddressSelect = (value: string) => {
    setSelectedAddress(value);
  };

  return (
    <Card className="fixed top-4 right-4 w-[400px] shadow-xl border-2">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
        <div className="flex items-center gap-2">
          <div className="rounded-full bg-neutral-100 p-2">
            <Wallet className="h-6 w-6" />
          </div>
          <div>
            <Select value={selectedAddress} onValueChange={handleAddressSelect}>
              <SelectTrigger className="w-[180px]">
                <SelectValue
                  placeholder={
                    selectedAddress === "all"
                      ? "All Wallets..."
                      : `${selectedAddress?.slice(0, 6)}...${selectedAddress?.slice(-6)}`
                  }
                />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Wallets...</SelectItem>
                {Object.values(addresses).map((address) => {
                  return address ? (
                    <SelectItem key={address} value={address}>
                      {address?.slice(0, 6)}...{address?.slice(-6)}
                    </SelectItem>
                  ) : null;
                })}
              </SelectContent>
            </Select>
          </div>
        </div>
        <div className="flex gap-2">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setShowBalances(false)}
          >
            <XMarkIcon className="h-5 w-5 text-foreground" />
          </Button>
        </div>
      </CardHeader>
      <CardContent className="space-y-6">
        <div>
          {isLoading ? (
            <Skeleton className="h-10 w-full" />
          ) : (
            <div className="text-3xl font-bold">
              $
              {filteredAndSortedBalances
                ?.reduce((acc, token) => acc + token.usd_amount!, 0)
                .toFixed(2)}
            </div>
          )}
          {/* We need to get an api to track historical data for the coins that the user holds */}
          {/* <div className="text-sm text-red-500">↓ $13.98 (1.95%)</div> */}
        </div>

        <div className="grid grid-cols-2 gap-4">
          <Button
            onClick={async () => {
              if (loading) return;
              if (preferredAgent !== superAgent) {
                setMessages([]);
              }
              await dispatch(selectPreferredAgentThunk(superAgent)).unwrap();
              await appendMessage("I want to buy some crypto", superAgent);
            }}
            className="bg-primary/10 hover:bg-primary/20 text-primary"
          >
            <CreditCard className="mr-2 h-4 w-4" />
            Buy
          </Button>
          <Button
            onClick={async () => {
              if (loading) return;
              if (preferredAgent !== superAgent) {
                setMessages([]);
              }
              await dispatch(selectPreferredAgentThunk(superAgent));
              await appendMessage(`I want to receive some crypto`, superAgent);
            }}
            className="bg-primary/10 hover:bg-primary/20 text-primary"
          >
            <Download className="mr-2 h-4 w-4" />
            Receive
          </Button>
        </div>

        <Tabs defaultValue="tokens" className="w-full">
          <TabsList className="grid w-full grid-cols-4">
            <TabsTrigger value="tokens">Tokens</TabsTrigger>

            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <TabsTrigger disabled value="nfts">
                    NFTs
                  </TabsTrigger>
                </TooltipTrigger>
                <TooltipContent>Coming Soon</TooltipContent>
              </Tooltip>
            </TooltipProvider>

            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  {" "}
                  <TabsTrigger disabled value="pools">
                    Pools
                  </TabsTrigger>
                </TooltipTrigger>
                <TooltipContent>Coming Soon</TooltipContent>
              </Tooltip>
            </TooltipProvider>

            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <TabsTrigger disabled value="activity">
                    Activity
                  </TabsTrigger>
                </TooltipTrigger>
                <TooltipContent>Coming Soon</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </TabsList>

          <TabsContent value="tokens" className="space-y-4">
            {/* TODO: I commented this out because we don't have the AI Agent Wallets, but based on the design it should be here */}
            {/* <Collapsible
              open={isAiWalletsOpen}
              onOpenChange={setIsAiWalletsOpen}
              className="space-y-2"
            >
              <CollapsibleTrigger className="flex w-full items-center justify-between rounded-lg border p-4 font-medium">
                <div className="flex items-center gap-2">
                  <Bot className="h-4 w-4" />
                  AI Agent Wallets
                </div>
                <ChevronDown
                  className={`h-4 w-4 transition-transform duration-200 ${
                    isAiWalletsOpen ? "rotate-180" : ""
                  }`}
                />
              </CollapsibleTrigger>
              <CollapsibleContent className="space-y-2">
                {aiWallets.map((wallet, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between rounded-lg border p-4"
                  >
                    <div className="flex items-center gap-2">
                      <Bot className="h-4 w-4" />
                      <span>{wallet.name}</span>
                    </div>
                    <span className="font-medium">{wallet.balance}</span>
                  </div>
                ))}
              </CollapsibleContent>
            </Collapsible> */}

            <ScrollArea className="h-[500px]">
              <div className="space-y-2">
                {isLoading ? (
                  <div className="space-y-2">
                    <Skeleton className="h-16 w-full" />
                    <Skeleton className="h-16 w-full" />
                    <Skeleton className="h-16 w-full" />
                    <Skeleton className="h-16 w-full" />
                  </div>
                ) : filteredAndSortedBalances ? (
                  filteredAndSortedBalances?.map(
                    (token, index) =>
                      token.amount > 0 &&
                      token.usd_amount! > 0.01 && (
                        <div
                          key={index}
                          className="flex items-center justify-between rounded-lg p-1"
                        >
                          <div className="flex items-center gap-3">
                            <div className="flex flex-col items-center justify-center gap-2 w-12">
                              <Image
                                image={token}
                                defaultImage={images.DefaultCryptoLogo}
                              />
                            </div>
                            <div>
                              <div className="font-medium">{token.name}</div>
                              <div className="text-[0.65rem] text-muted-foreground">
                                {token.chain}
                              </div>
                              <div className="text-sm text-muted-foreground">
                                {token.amount.toFixed(6)}
                              </div>
                            </div>
                          </div>
                          <div className="text-right">
                            <div className="font-medium">
                              ${token.usd_amount?.toFixed(2)}
                            </div>

                            {/* Regardles of the design, we don't have this data yet, or if the marketcap api does return it, 
                            we are not sending it back on the response. */}
                            {/* <div
                      className={`text-sm ${
                        token.change >= 0 ? "text-green-500" : "text-red-500"
                      }`}
                    >
                      {token.change >= 0 ? "▲" : "▼"} {Math.abs(token.change)}%
                    </div> */}
                          </div>
                        </div>
                      )
                  )
                ) : (
                  <div className="text-center text-muted-foreground">
                    No balances found
                  </div>
                )}
              </div>
            </ScrollArea>
          </TabsContent>
          <TabsContent value="nfts">NFTs content</TabsContent>
          <TabsContent value="pools">Pools content</TabsContent>
          <TabsContent value="activity">Activity content</TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  );
}
