import { useEffect, useRef, useState } from "react";
import { WalletType, useWallets } from "../hooks/wallets/use-wallets";
import { WalletProvider } from "../hooks/wallets/providers/wallet-provider";
import { setIsLoading, updateBalances } from "../redux/slices/user";
import { serverApi } from "../services/server";
import { useAppDispatch } from "../redux/hooks";

import { Button } from "../components/ui/button";
import { Checkbox } from "../components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../components/ui/dialog";
import { ScrollArea } from "../components/ui/scroll-area";
import { ThemedButton } from "./themed/ThemedButton";
import { LogOut } from "lucide-react";

function shortenAddress(address: string) {
  return address.slice(0, 6) + "..." + address.slice(-6);
}

export default function ConnectWalletButton(props: {
  className?: string;
  chatStarted?: boolean;
}) {
  const {
    addresses,
    connect,
    disconnect,
    loading,
    agentsPreference,
    setAgentsPreference,
  } = useWallets();

  const dispatch = useAppDispatch();
  const loadingBalances = useRef(false);

  const [previousAddresses, setPreviousAddresses] = useState(addresses);

  const walletTypeToProviders = [
    { name: WalletType.EVM, iconSrc: "EvmIcon", options: [WalletProvider.EVM] },
    {
      name: WalletType.APTOS,
      iconSrc: "AptosIcon",
      options: [WalletProvider.APTOS],
    },
    {
      name: WalletType.COSMOS,
      iconSrc: "CosmosIcon",
      options: [WalletProvider.COSMOS],
    },
    {
      name: WalletType.SOLANA,
      iconSrc: "SolanaIcon",
      options: [WalletProvider.PHANTOM, WalletProvider.TIPLINK],
    },
    { name: WalletType.SUI, iconSrc: "SuiIcon", options: [WalletProvider.SUI] },
    {
      name: WalletType.BITCOIN,
      iconSrc: "BitcoinIcon",
      options: [WalletProvider.BITCOIN],
    },
  ];

  useEffect(() => {
    const newWalletType = Object.keys(addresses).find(
      (key) =>
        addresses[key as keyof typeof addresses] !== null &&
        previousAddresses[key as keyof typeof previousAddresses] === null
    );

    if (newWalletType) {
      loadingBalances.current = true;

      // Get only the new connected wallet address
      const newAddresses = Object.keys(addresses).reduce(
        (acc, key) => {
          acc[key as keyof typeof addresses] =
            key === newWalletType
              ? addresses[key as keyof typeof addresses]
              : null;
          return acc;
        },
        {} as typeof addresses
      );

      dispatch(setIsLoading(true));

      // We now can call the balances endpoint with the new address only (instead of all of them)
      serverApi
        .getBalances(
          newAddresses.EVM,
          newAddresses.SOLANA,
          newAddresses.SUI,
          newAddresses.COSMOS,
          newAddresses.BITCOIN
        )
        .then((response) => {
          dispatch(updateBalances(response));
        })
        .catch((error) => {
          console.error("Error fetching balances: ", error);
        })
        .finally(() => {
          loadingBalances.current = false;
        });

      setPreviousAddresses(addresses);
    }
  }, [addresses]);

  return (
    <div className="flex gap-2">
      <Dialog>
        <DialogTrigger>
          <ThemedButton>
            {Object.values(addresses).filter((w) => w !== null).length > 0
              ? `(${Object.values(addresses).filter((w) => w !== null).length}) Connected wallets`
              : "Connect Wallet"}
          </ThemedButton>
        </DialogTrigger>
        <DialogContent className="bg-background text-foreground sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle className="text-2xl font-bold">
              Connect Wallet
            </DialogTitle>
          </DialogHeader>
          <ScrollArea className="h-[400px] pr-4">
            {walletTypeToProviders.map((wallet) => (
              <div key={wallet.name} className="mb-4">
                {addresses[wallet.name] ? (
                  <Button
                    variant="outline"
                    className="bg-background text-foreground w-full justify-between mb-2 text-left flex items-center"
                    onClick={async () => {
                      await connect(wallet.name, wallet.options[0]);
                    }}
                  >
                    <div className="flex items-center">
                      <img
                        src={require(`../assets/images/${wallet.iconSrc}.png`)}
                        alt={`${wallet.name} Icon`}
                        className="mr-2 h-6 w-6"
                      />
                      {shortenAddress(addresses[wallet.name] as string)}
                    </div>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        // TODO - Remove Balances on disconnect!
                        disconnect(wallet.name);
                      }}
                    >
                      <LogOut className="h-4 w-4" />
                    </button>
                  </Button>
                ) : (
                  wallet.options.map((option) => (
                    <Button
                      key={option}
                      variant="outline"
                      className="bg-background text-foreground w-full justify-between mb-2 text-left flex items-center"
                      onClick={async () => {
                        await connect(wallet.name, option);
                      }}
                    >
                      <div className="flex items-center">
                        <img
                          src={require(
                            `../assets/images/${wallet.iconSrc}.png`
                          )}
                          alt={`${wallet.name} Icon`}
                          className="mr-2 h-6 w-6"
                        />
                        {loading[wallet.name] ? (
                          <p>Connecting wallet...</p>
                        ) : (
                          option.charAt(0).toUpperCase() + option.slice(1)
                        )}
                      </div>
                    </Button>
                  ))
                )}
              </div>
            ))}
          </ScrollArea>
          <div className="flex flex-col items-center space-x-2 mt-2">
            <div className="flex items-center space-x-2">
              <Checkbox
                id="agentWallets"
                checked={agentsPreference}
                disabled={props.chatStarted}
                onCheckedChange={() => setAgentsPreference(!agentsPreference)}
              />
              <label
                htmlFor="agentWallets"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Use agents wallets for more flexibility and power
              </label>
            </div>
            {agentsPreference && (
              <div className="mt-4">
                <p className="text-foreground text-sm text-justify">
                  Agent wallets are managed by the agents. So you can confirm
                  transactions with natural language and ask more complex tasks
                  without much involvement. To get started you'll need to add
                  funds to them. You can check your address right above.
                </p>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
