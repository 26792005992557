import { useCallback, useMemo, useState } from "react";
import { Button } from "../components/ui/button";
import { Card, CardContent } from "../components/ui/card";
import { Tabs, TabsList, TabsTrigger } from "../components/ui/tabs";
import { useChat } from "../hooks/use-chat";
import SphereIconLogoGreen from "../assets/images/SphereIconLogoGreen.png";
import { AgentProps } from "../components/agent-functionality";
import { useAppDispatch } from "../redux/hooks";
import { useNavigate } from "react-router-dom";
import { FaExclamationCircle } from "react-icons/fa";
import { AgentSupportedActions } from "../components/agent-functionality";
import { FilterDialogButton } from "../components/themed/filter-dialog";
import { XMarkIcon } from "@heroicons/react/24/solid";
import SearchBarOverlay from "../components/search-bar-overlay";
import { AgentCommandCard } from "../components/search-bar-overlay";
import { ScrollArea } from "../components/ui/scroll-area";
import { selectPreferredAgentThunk } from "../redux/slices/gptThunk";

export default function AgentsPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleCardClick = useCallback(
    async (agent: AgentProps) => {
      await dispatch(selectPreferredAgentThunk(agent)).unwrap();
      setMessages([]);
      navigate("/chat");
    },
    [dispatch, navigate]
  );

  const {
    availableAgents: agents,
    superAgent,
    aiPaymentAgent,
    allChains,
    setMessages,
  } = useChat();

  const [tabFilter, setTabFilter] = useState("top-picks");

  const [filters, setFilters] = useState<{
    chains: string[];
    action: string;
  }>({ chains: [], action: "all" });

  const handleApplyFilters = (appliedFilters: {
    selectedChains: string[];
    selectedAction: string;
  }) => {
    setFilters((prev) => ({
      ...prev,
      action: appliedFilters.selectedAction,
      chains: appliedFilters.selectedChains,
    }));
    setTabFilter(appliedFilters.selectedAction.toLowerCase());
  };

  const shuffleArray = (array: AgentProps[]) => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  const onUnselectChain = (chainToRemove: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      chains: prevFilters.chains.filter((chain) => chain !== chainToRemove),
    }));
  };

  const onUnselectAction = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      action: "ALL",
    }));
    setTabFilter("all");
  };

  const filteredAgents = useMemo(() => {
    return agents.filter((agent: AgentProps) => {
      const tabFilterLower = tabFilter.toLowerCase();

      const matchesTabFilter =
        tabFilter === "all" ||
        tabFilter === "top-picks" ||
        agent.description?.toLowerCase().includes(tabFilterLower) ||
        agent.action.toLowerCase().includes(tabFilterLower);

      const matchesChainFilter =
        filters.chains.length === 0 ||
        filters.chains.some((chain) => agent.supportedChains.includes(chain));

      return matchesTabFilter && matchesChainFilter;
    });
  }, [agents, tabFilter, filters.chains]);

  // Selected Agents for the Featured and Trending sections
  const selectedAgents = useMemo(() => {
    const topPicks = agents.filter((agent: AgentProps) => {
      if (agent.name === "Decent Swaps Agent" && agent.active) return true;
      else if (agent.name === "Jupiter Swaps Agent" && agent.active)
        return true;
      else return false;
    });

    const trending = agents.filter((agent: AgentProps) => {
      if (agent.name === "Cetus Agent" && agent.active) return true;
      else if (agent.name === "Coinmarketcap Agent" && agent.active)
        return true;
      else if (agent.name === "Banxa Onramp Agent" && agent.active) return true;
      else return false;
    });

    topPicks.unshift(superAgent, aiPaymentAgent);

    return { topPicks, trending };
  }, [agents]);

  // Select 3 or 4 random agents for Swap / Bridge / Dex / Onramp
  // 4 for using the "Cards" format and 3 for the "Horizontal list" format
  const randomlySelectedAgents = useMemo(() => {
    const swapAgents = agents.filter(
      (agent: AgentProps) => agent.action === AgentSupportedActions.SWAP
    );
    const bridgeAgents = agents.filter(
      (agent: AgentProps) => agent.action === AgentSupportedActions.BRIDGE
    );
    const dexAgents = agents.filter(
      (agent: AgentProps) => agent.action === AgentSupportedActions.DEX
    );
    const onrampAgents = agents.filter(
      (agent: AgentProps) => agent.action === AgentSupportedActions.ONRAMP
    );

    return {
      swapAgents: {
        title: "Swap Agents",
        description: "Explore our most popular Swap Agents.",
        agents: shuffleArray(swapAgents).slice(0, 4),
      },
      dexAgents: {
        title: "DEX Agents",
        description: "Enhance your trading experience with our top Dex Agents.",
        agents: shuffleArray(dexAgents).slice(0, 3),
      },
      bridgeAgents: {
        title: "Bridge Agents",
        description: "AI Agents for effortless Cross-Chain Transactions",
        agents: shuffleArray(bridgeAgents).slice(0, 4),
      },
      onrampAgents: {
        title: "Onramp Agents",
        description: "Your gateway for onramping Fiat to Crypto",
        agents: shuffleArray(onrampAgents).slice(0, 3),
      },
    };
  }, [agents]);

  // Get Actions list and add 'Top Picks' / 'All'
  // Remove 'BALANCE', 'NFT', 'SCHEDULE' so we get only 1 line tab
  // TODO: Ask Ryan which ones he wants to display
  const categoryList = useMemo(
    () =>
      ["TOP-PICKS", "ALL", ...Object.values(AgentSupportedActions)].filter(
        (action) =>
          action !== "BALANCE" && action !== "NFT" && action !== "SCHEDULE"
      ),
    [Object.values(AgentSupportedActions)]
  );

  return (
    <ScrollArea className="h-screen w-full">
      <div className="flex flex-col h-full w-full">
        <main className="px-12 mb-4 mt-4">
          <h1 className="text-4xl font-bold text-center mb-4">Agents</h1>
          <p className="text-center text-muted-foreground mb-8 max-w-2xl mx-auto">
            Discover and unlock AI agents that simplify blockchain interactions
            with seamless transactions, enhanced insights, and powerful tools to
            manage your assets effortlessly.
          </p>

          <div className="flex flex-row gap-2 items-center max-w-2xl mx-auto mb-2">
            <SearchBarOverlay />
            <FilterDialogButton
              allChains={allChains}
              filtersApplied={
                filters.chains.length +
                (filters.action.toUpperCase() !== "ALL" &&
                filters.action.toUpperCase() !== "TOP-PICKS"
                  ? 1
                  : 0)
              }
              onApplyFilter={handleApplyFilters}
            />
          </div>

          <div className="flex flex-wrap justify-center gap-x-2 gap-y-1 mb-2">
            {!(
              filters.action.toUpperCase() === "ALL" ||
              filters.action.toLocaleUpperCase() === "TOP-PICKS"
            ) && (
              <div
                className="flex flex-row flex-wrap gap-3 items-center"
                onClick={() => onUnselectAction()}
              >
                <Button
                  variant="outline"
                  className="flex items-center px-2 py-1 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm hover:bg-gray-100 dark:hover:bg-gray-800"
                  onClick={() =>
                    setFilters((prev) => ({ ...prev, action: "ALL" }))
                  }
                >
                  <span className="text-sm font-medium text-foreground">
                    {filters.action.toUpperCase()}
                  </span>
                  <XMarkIcon className="h-5 w-auto" />
                </Button>
              </div>
            )}
            {filters.chains.length > 0 && (
              <>
                {filters.chains.map((chain: string, index: number) => (
                  <Button
                    key={index}
                    variant="outline"
                    className="flex items-center px-2 py-1 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm hover:bg-gray-100 dark:hover:bg-gray-800"
                    onClick={() => onUnselectChain(chain)}
                  >
                    <span className="text-sm font-medium text-foreground">
                      {chain}
                    </span>
                    <XMarkIcon className="h-5 w-auto" />
                  </Button>
                ))}
              </>
            )}
          </div>
          <Tabs
            defaultValue="top-picks"
            value={tabFilter}
            onValueChange={(value) => {
              setTabFilter(value);
              filters.action = value;
            }}
            className="mx-auto mb-4 max-w-2xl"
          >
            <TabsList className="h-full flex flex-wrap gap-2 justify-center">
              {categoryList.map((category) => (
                <TabsTrigger
                  key={category.toLowerCase()}
                  value={category.toLowerCase()}
                >
                  {category.charAt(0).toUpperCase() +
                    category.slice(1).toLowerCase()}
                </TabsTrigger>
              ))}
            </TabsList>
          </Tabs>

          {agents.length === 0 ? (
            <div className="flex justify-center items-center h-12 rounded-lg p-4">
              <div className="flex items-center space-x-2">
                <FaExclamationCircle className="h-10 w-auto text-yellow-500" />
                <p className="text-lg font-medium text-center">
                  No matches. Please try a different search.
                </p>
              </div>
            </div>
          ) : tabFilter !== "top-picks" ? (
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4">
              {filteredAgents.map((agent: AgentProps, index: number) => (
                <div
                  className="border border-lg border-gray-400 p-2 rounded-xl cursor-pointer"
                  onClick={async () => {
                    await dispatch(selectPreferredAgentThunk(agent)).unwrap();
                    navigate("/chat");
                  }}
                >
                  <AgentCommandCard key={index} agent={agent} />
                </div>
              ))}
            </div>
          ) : (
            <>
              <section className="mb-12">
                <h2 className="text-2xl font-semibold mb-4">Featured</h2>
                <p className="text-muted-foreground mb-6">
                  Curated top picks from this week
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {selectedAgents.topPicks.map(
                    (agent: AgentProps, index: number) => (
                      <AgentCard
                        key={index}
                        agent={agent}
                        onCardClick={handleCardClick}
                      />
                    )
                  )}
                </div>
              </section>

              <section className="mb-12">
                <h2 className="text-2xl font-semibold mb-4">Trending</h2>
                <p className="text-muted-foreground mb-6">
                  Most popular Agents by our community
                </p>
                <div className="space-y-4">
                  {selectedAgents.trending.map(
                    (agent: AgentProps, index: number) => (
                      <TrendingAgentCard
                        key={index}
                        rank={index + 1}
                        agent={agent}
                        onCardClick={handleCardClick}
                      />
                    )
                  )}
                </div>
              </section>

              {Object.entries(randomlySelectedAgents).map(([key, value]) => (
                <section key={key} className="mb-12">
                  <h2 className="text-2xl font-semibold mb-4">{value.title}</h2>
                  <p className="text-muted-foreground mb-6">
                    {value.description}
                  </p>
                  <div
                    className={
                      value.agents.length === 3
                        ? "space-y-4"
                        : "grid grid-cols-1 md:grid-cols-2 gap-6"
                    }
                  >
                    {value.agents.length === 4
                      ? value.agents.map((agent: AgentProps, index: number) => (
                          <AgentCard
                            key={index}
                            agent={agent}
                            onCardClick={handleCardClick}
                          />
                        ))
                      : value.agents.map((agent: AgentProps, index: number) => (
                          <TrendingAgentCard
                            key={index}
                            rank={index + 1}
                            agent={agent}
                            onCardClick={handleCardClick}
                          />
                        ))}
                  </div>
                </section>
              ))}
            </>
          )}
        </main>
      </div>
    </ScrollArea>
  );
}

function AgentCard({
  agent,
  onCardClick,
}: {
  agent: AgentProps;
  onCardClick: (agent: AgentProps) => void;
}) {
  return (
    <>
      {agent && (
        <Card
          className="h-54 cursor-pointer"
          onClick={() => onCardClick(agent)}
        >
          <CardContent className="p-6 h-full">
            <div className="flex flex-col h-full justify-between">
              <div className="flex items-start space-x-4">
                <div className="w-12 h-12 rounded-lg p-2 bg-primary/5 dark:bg-gray-700 flex items-center justify-center">
                  <img
                    className="min-w-8 min-h-8 object-contain rounded-full"
                    src={agent.img || SphereIconLogoGreen}
                    alt={agent.name}
                  />
                </div>
                <div>
                  <h3 className="font-semibold text-lg">{agent.name}</h3>
                  <p className="text-sm text-muted-foreground mb-2">
                    {agent.description}
                  </p>
                </div>
              </div>
              <p className="text-xs text-muted-foreground text-right self-end">
                {agent.author ? agent.author : "By SphereOne"}
              </p>
            </div>
          </CardContent>
        </Card>
      )}
    </>
  );
}

function TrendingAgentCard({
  rank,
  agent,
  onCardClick,
}: {
  rank: number;
  agent: AgentProps;
  onCardClick: (agent: AgentProps) => void;
}) {
  return (
    <div
      className="flex items-center space-x-4 cursor-pointer"
      onClick={() => onCardClick(agent)}
    >
      <span className="text-2xl font-bold text-muted-foreground">{rank}</span>
      <img
        src={agent.img || SphereIconLogoGreen}
        alt={agent.name}
        width={50}
        height={50}
        className="rounded-full p-2 bg-gray-200 dark:bg-gray-700"
      />
      <div>
        <h4 className="font-semibold">{agent.name}</h4>
        <p className="text-sm text-muted-foreground">{agent.description}</p>
      </div>
    </div>
  );
}
