import { useState } from "react";
import {
  PlusIcon,
  SwapRoute,
  InfoIcon,
} from "../../../assets/icons/flowbite-icons";
import { Submit } from "../../../hooks/use-chat";
import { useAppDispatch } from "../../../redux/hooks";
import {
  updateBalances,
  updateTokenConfetti,
} from "../../../redux/slices/user";
import {
  AssembledTransaction,
  useWallets,
} from "../../../hooks/wallets/use-wallets";
import { serverApi } from "../../../services/server";
import { AlertDialog } from "../../themed/alert-dialog";
import { Tooltip } from "react-tooltip";
import { images } from "../../../assets/images";

interface IDoubleSideLiquidity {
  from_address: string;
  from_token_a: {
    address: string;
    symbol: string;
    decimals: string;
    logoURI: string;
    chain: string;
  };
  from_token_b: {
    address: string;
    symbol: string;
    decimals: string;
    logoURI: string;
    chain: string;
  };
  from_chain: string;
  from_chain_id: number;
  from_token_a_amount: number;
  from_token_b_amount: number;
  from_token_a_usd_amount: number;
  from_token_b_usd_amount: number;
  range_lower_price: number;
  range_upper_price: number;
  current_pool_price: number;
  from_token_usd: number;
  transactions: AssembledTransaction[];
  strategy?: string;
  type: "DEPOSIT" | "WITHDRAW";
  tool: string;
  agent: string;
}

const strategieTooltipContent = `
- SAFE: Range Min Price -20% || Range Max Price +40% of the current price<br />
- COMMON: Range Min Price -10% || Range Max Price +20% of the current price<br />
- EXPERT: Range Min Price -5% || Range Max Price +10% of the current price<br />
- STABLECOINS: Range Min Price -1.5% || Range Max Price +1.5% of the current price<br />
- FULL-RANGE: No price range, you add liquidity in the range 0 to infinity<br />
`;

export const DoubleSideLiquidityQuote = ({
  data,
  submit,
  active,
  onDone,
}: {
  data: IDoubleSideLiquidity;
  active: boolean;
  onDone: () => void;
  submit: (data: Submit) => void;
}) => {
  const [error, setError] = useState<string | null>(null);
  const [transactionsCompleted, setTransactionsCompleted] =
    useState<boolean>(false);
  const { signTransactions, addresses } = useWallets();
  const dispatch = useAppDispatch();

  const onAddLiquidity = async () => {
    signTransactions(data.transactions).then(async (res) => {
      if (res.error) {
        if (res.error === "switching network") {
          onAddLiquidity();
          return;
        }
        setError("Error: " + res.error);
        return;
      } else if (res.hashes.length !== data.transactions.length) {
        setError("Error: not all transactions were signed");
        return;
      }

      setTransactionsCompleted(true);
      onDone();

      submit({
        transaction: data,
        signature: res.hashes[res.hashes.length - 1],
        walletAddress: data.from_address || addresses.EVM!,
        protocol_name: data.agent,
      });

      dispatch(updateTokenConfetti(images.SphereLogo));

      // update the balances
      serverApi
        .getBalances(
          addresses.EVM!,
          addresses.SOLANA!,
          addresses.SUI!,
          addresses.COSMOS!,
          addresses.BITCOIN!
        )
        .then((balances) => {
          dispatch(updateBalances(balances));
        });
    });
  };

  return (
    <div className="bg-gray-800 border border-gray-700 shadow-sm rounded-lg p-4 mt-4 flex flex-col items-center gap-4  max-w-[550px]">
      <div className="flex flex-row items-center justify-center px-1 w-full text-white font-semibold text-md">
        {data.type === "DEPOSIT"
          ? "Supply Liquidity Transaction"
          : "Withdraw Liquidity Transaction"}
      </div>
      <div className="bg-gray-700 border border-gray-600 rounded-lg p-3 flex justify-between items-center gap-4 w-full h-70px">
        {/* Involved Tokens */}
        <div className="flex flex-row items-start gap-2 w-1/3">
          <img
            src={data.from_token_a.logoURI}
            alt={data.from_token_a.symbol}
            className="w-6 h-6 rounded-full"
          />
          <div className="flex flex-col justify-center gap-1">
            <span className="text-white font-medium text-sm leading-6">
              {data.from_token_a_amount.toFixed(5)} {data.from_token_a.symbol}
            </span>
            <span className="text-xs text-left">
              {data.from_chain.toUpperCase()}
            </span>
            <span className="text-xs text-left">
              ${data.from_token_a_usd_amount.toFixed(2)}
            </span>
          </div>
        </div>

        <div>
          <PlusIcon />
        </div>

        <div className="flex flex-row items-start gap-2 w-1/3">
          <img
            src={data.from_token_b.logoURI}
            alt={data.from_token_b.symbol}
            className="w-6 h-6 rounded-full"
          />
          <div className="flex flex-col justify-center gap-1">
            <span className="text-white font-medium text-sm leading-6">
              {data.from_token_b_amount.toFixed(5)} {data.from_token_b.symbol}
            </span>
            <span className="text-xs text-left">
              {data.from_chain.toUpperCase()}
            </span>
            <span className="text-xs text-left">
              ${data.from_token_b_usd_amount.toFixed(2)}
            </span>
          </div>
        </div>
      </div>

      {/* Other details */}

      <div className="flex flex-row items-center px-1 w-full">
        <span className="text-white font-semibold text-sm leading-6 ml-2">
          Wallet Address:
        </span>
        <span className="text-white text-sm leading-6 ml-auto">
          {shortenAddress(data.from_address)}
        </span>
      </div>

      {data.strategy !== "FULL-RANGE" && (
        <div className="flex flex-row items-center px-1 w-full">
          <span className="text-white font-semibold text-sm leading-6 ml-2">
            Range Min Price:
          </span>
          <span className="text-white text-sm leading-6 ml-auto">
            {data.range_lower_price.toFixed(4)}{" "}
            {data.from_token_b.symbol + " per " + data.from_token_a.symbol}
          </span>
        </div>
      )}

      <div className="flex flex-row items-center px-1 w-full">
        <span className="text-white font-semibold text-sm leading-6 ml-2">
          Current Price:
        </span>
        <span className="text-white text-sm leading-6 ml-auto">
          {data.current_pool_price.toFixed(4)}{" "}
          {data.from_token_b.symbol + " per " + data.from_token_a.symbol}
        </span>
      </div>

      {data.strategy !== "FULL-RANGE" && (
        <div className="flex flex-row items-center px-1 w-full">
          <span className="text-white font-semibold text-sm leading-6 ml-2">
            Range Max Price:
          </span>
          <span className="text-white text-sm leading-6 ml-auto">
            {data.range_upper_price.toFixed(4)}{" "}
            {data.from_token_b.symbol + " per " + data.from_token_a.symbol}
          </span>
        </div>
      )}

      <div className="flex flex-row items-center px-1 w-full">
        <span className="text-white font-semibold text-sm leading-6 ml-2">
          Total Amount USD:
        </span>
        <span className="text-white text-sm leading-6 ml-auto">
          $
          {(
            data.from_token_a_usd_amount + data.from_token_b_usd_amount
          ).toFixed(2)}
        </span>
      </div>

      {data.strategy && (
        <div className="flex flex-row items-center px-1 w-full">
          <span
            className="text-gray-400 text-sm leading-6"
            data-tooltip-id="my-tooltip"
            data-tooltip-html={strategieTooltipContent}
          >
            <InfoIcon />
          </span>
          <Tooltip id="my-tooltip" place="top-start" />
          <span className="text-white font-semibold text-sm leading-6 ml-2">
            Strategie:
          </span>
          <span className="text-white text-sm leading-6 ml-auto">
            {data.strategy}
          </span>
        </div>
      )}

      {data.tool && (
        <div className="flex flex-row justify-start items-center px-1 w-full">
          <SwapRoute />
          <span className="text-gray-400 text-sm leading-6 ml-2">
            {data.tool} via
          </span>
          <span className="text-gray-400 font-semibold text-sm leading-6 ml-2">
            {data.agent}
          </span>
        </div>
      )}

      <div className="text-left w-full grid grid-cols-2 gap-2">
        <button
          onClick={onDone}
          disabled={transactionsCompleted}
          className="w-full text-white text-sm bg-gray-500 hover:bg-gray-500/80 rounded-lg px-4 py-2 disabled:bg-gray-500/70 disabled:text-gray-300"
        >
          Cancel
        </button>
        <button
          onClick={onAddLiquidity}
          disabled={transactionsCompleted}
          className="w-full text-white text-sm bg-blue-500 hover:bg-blue-500/80 rounded-lg px-4 py-2 disabled:bg-blue-500/70 disabled:text-gray-300"
        >
          Confirm
        </button>
      </div>

      <div className="flex flex-row items-center px-1 w-full">
        <span className="text-gray-400 font-semibold text-xs leading-6 ml-auto">
          AI Agent:
        </span>
        <span className="text-gray-400 text-xs leading-6 ml-2">
          {data.agent}
        </span>
      </div>

      {error && (
        <AlertDialog
          open={!!error}
          title={"Oops!"}
          description={error}
          actionLabel="Accept"
          onActionPress={() => setError("")}
        />
      )}
    </div>
  );
};

function shortenAddress(address: string) {
  return address.slice(0, 6) + "..." + address.slice(-6);
}
