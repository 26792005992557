import React, { useMemo } from "react";
import { images } from "../../../assets/images";

const NFTsView: React.FC<{ data: { nfts: any[] } }> = ({
  data,
}): React.ReactElement => {
  const currentNfts = useMemo(() => data.nfts.flat(), [data.nfts]);

  return (
    <div className="flex flex-row gap-x-5 gap-y-2 flex-wrap justify-center items-center">
      {currentNfts
        .filter((nft) => nft !== null)
        .map((nft: any, index: number) => (
          <div key={index}>
            <img
              src={nft.image ?? images.AnotherSphereLogo}
              alt={nft.name}
              className="w-64 rounded"
            />
          </div>
        ))}
    </div>
  );
};

export default NFTsView;
