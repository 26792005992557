import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getCountryCode } from "./userThunk";
import { Balance } from "../../components/chat/balances";
import { BalanceResponse, SuggestedAction } from "../../services/server";

export interface UserState {
  countryCode: string;
  balances?: Balance[];
  suggestedActions?: SuggestedAction[];
  destinationToken?: string;
  errors?: {};
  isLoading?: boolean;
}

const initialState: UserState = {
  countryCode: "US",
  isLoading: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateBalances: (state, action: PayloadAction<BalanceResponse>) => {
      state.balances = [...(state.balances ?? []), ...action.payload.balances];
      state.suggestedActions = [
        ...(state.suggestedActions ?? []),
        ...action.payload.suggestedActions,
      ].sort((a, b) => (b.relevanceScore ?? 0) - (a.relevanceScore ?? 0));
      state.errors = action.payload.errors;
      state.isLoading = false;
    },
    updateTokenConfetti: (state, action) => {
      state.destinationToken = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCountryCode.fulfilled, (state, action) => {
      state.countryCode = action.payload || "US";
    });
    builder.addCase(getCountryCode.rejected, (state, action) => {
      state.countryCode = "US";
    });
  },
});

export const { updateBalances, updateTokenConfetti, setIsLoading } =
  userSlice.actions;
export default userSlice.reducer;
