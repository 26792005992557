"use client";

import * as React from "react";
import {
  Home,
  Users,
  FileText,
  Settings,
  History,
  MessageCircle,
} from "lucide-react";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuButton,
} from "../components/ui/sidebar";
import { useNavigate, useLocation } from "react-router-dom";
import { ModeToggle } from "./toggle-theme";
import { images } from "../assets/images";
import { useTheme } from "./theme-provider";
import SettingsPage from "../screens/settings";
import { Dialog, DialogContent, DialogTrigger } from "./ui/dialog";
import { useAppSelector } from "../redux/hooks";

export default function SideBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { theme } = useTheme();
  const { preferredAgent } = useAppSelector((state) => state.gpt);

  // Determine the active tab based on the current path
  const getActiveTab = () => {
    if (location.pathname === "/") return "Home";
    if (location.pathname === "/agents") return "Agents";
    if (location.pathname === "/chat") return "Chat";
    if (location.pathname === "/chat-history") return "Chat History";
    return "";
  };

  const activeTab = getActiveTab();

  return (
    <Sidebar className="border-r" collapsible="offcanvas">
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton size="lg" asChild>
              <p
                className="flex items-center gap-2 cursor-pointer"
                onClick={() => navigate("/")}
              >
                <img
                  src={
                    theme === "light"
                      ? images.DefaultAgentLogo
                      : images.SphereLogoWhite
                  }
                  alt="SphereOne"
                  className="h-6 w-6"
                />
                <span className="text-xl font-bold">SphereOne</span>
              </p>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent className="flex flex-col justify-between">
        <SidebarMenu className="flex flex-col gap-2">
          {/* Home */}
          <SidebarMenuItem>
            <SidebarMenuButton
              asChild
              className={`transition-all transform duration-300 ease-in-out ${activeTab === "Home" ? "mx-2" : ""}`}
            >
              <p
                className={`flex items-center gap-2 cursor-pointer ${
                  activeTab === "Home" ? "bg-gray-200 dark:bg-gray-800" : ""
                }`}
                onClick={() => navigate("/")}
              >
                <Home className="h-4 w-4" />
                <span>Home</span>
              </p>
            </SidebarMenuButton>
          </SidebarMenuItem>

          {/* Agents */}
          <SidebarMenuItem>
            <SidebarMenuButton
              asChild
              className={`transition-all transform duration-300 ease-in-out ${activeTab === "Agents" ? "mx-2" : ""}`}
            >
              <p
                className={`flex items-center gap-2 cursor-pointer ${
                  activeTab === "Agents" ? "bg-gray-200 dark:bg-gray-800" : ""
                }`}
                onClick={() => navigate("/agents")}
              >
                <Users className="h-4 w-4" />
                <span>Agents</span>
              </p>
            </SidebarMenuButton>
          </SidebarMenuItem>

          {/* Chat */}
          {preferredAgent && (
            <SidebarMenuItem>
              <SidebarMenuButton
                asChild
                className={`transition-all transform duration-300 ease-in-out ${activeTab === "Chat" ? "mx-2" : ""}`}
              >
                <p
                  className={`flex items-center gap-2 cursor-pointer ${
                    activeTab === "Chat" ? "bg-gray-200 dark:bg-gray-800" : ""
                  }`}
                  onClick={() => navigate("/chat")}
                >
                  <MessageCircle className="h-4 w-4" />
                  <span>Chat</span>
                </p>
              </SidebarMenuButton>
            </SidebarMenuItem>
          )}

          {/* Chat History */}
          <SidebarMenuItem>
            <SidebarMenuButton
              asChild
              className={`transition-all transform duration-300 ease-in-out ${activeTab === "Chat History" ? "mx-2" : ""}`}
            >
              <p
                className={`flex items-center gap-2 cursor-pointer ${
                  activeTab === "Chat History"
                    ? "bg-gray-200 dark:bg-gray-800"
                    : ""
                }`}
              >
                <History className="h-4 w-4" />
                <span>
                  Chat History{" "}
                  <span className="text-xs text-gray-500 font-normal italic">
                    (Coming Soon)
                  </span>
                </span>
              </p>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarContent>
      <SidebarFooter>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton asChild>
              <ModeToggle />
            </SidebarMenuButton>
          </SidebarMenuItem>

          <SidebarMenuItem>
            <SidebarMenuButton asChild>
              <p
                className={`flex items-center gap-2 cursor-pointer`}
                onClick={() =>
                  window.open(
                    "https://sphereone.notion.site/Surface-Area-d4883498ae5f41909e7fae69795645ad",
                    "_blank"
                  )
                }
              >
                <FileText className="h-4 w-4" />
                <span>Docs</span>
              </p>
            </SidebarMenuButton>
          </SidebarMenuItem>

          <Dialog>
            <SidebarMenuItem>
              <SidebarMenuButton asChild>
                <DialogTrigger>
                  <p className={`flex items-center gap-2 cursor-pointer`}>
                    <Settings className="h-4 w-4" />
                    <span>Settings</span>
                  </p>
                </DialogTrigger>
              </SidebarMenuButton>
            </SidebarMenuItem>
            <DialogContent className="max-w-lg mx-auto h-[90vh]">
              <SettingsPage />
            </DialogContent>
          </Dialog>
        </SidebarMenu>
      </SidebarFooter>
    </Sidebar>
  );
}
