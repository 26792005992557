import { BigNumberish, ethers } from "ethers";

async function signEVMTransaction(transaction: ethers.TransactionRequest) {
  const provider = await getEvmConnection(transaction.chainId as number);

  const signer = await provider.getSigner();
  const accounts = await signer.getAddress();
  if (!accounts) throw new Error("No accounts found");

  if (
    transaction.maxFeePerGas != null &&
    transaction.maxPriorityFeePerGas != null
  ) {
    const maxFeePerGas = adjustMaxFeePerGas(
      transaction.maxFeePerGas,
      transaction.maxPriorityFeePerGas
    );
    transaction.maxFeePerGas = maxFeePerGas;
    if (transaction.gasPrice != null) {
      // Squid sometimes returns transactionRequest with both gasPrice and maxFeePerGas. Weird.
      // So we delete gasPrice to avoid conflicts.
      // Tx can't have both gasPrice and maxFeePerGas
      delete transaction.gasPrice;
    }
  }
  return signer.sendTransaction({
    ...transaction,
    value: transaction.value ? transaction.value.toString() : undefined,
  });
}

export async function signEvmTransactionWithRetry(
  transaction: any,
  retry = 0
): Promise<ethers.TransactionResponse> {
  try {
    if (typeof transaction === "string") {
      const cleanTransactionString = transaction.replace(/'/g, '"');
      transaction = JSON.parse(cleanTransactionString);
    }
    return await signEVMTransaction(transaction);
  } catch (e: any) {
    const txReverted =
      e?.data?.message?.includes("execution reverted") ??
      e?.error?.message?.includes("execution reverted");
    if (txReverted && retry <= 10) {
      await new Promise((resolve) => setTimeout(resolve, 5000));
      return await signEvmTransactionWithRetry(transaction, retry + 1);
    } else {
      console.error(e);
      throw new Error(
        e?.info?.error?.message ??
          e?.error?.message ??
          e.message ??
          "Error signing transaction"
      );
    }
  }
}

export async function waitForEVMTransaction(txHash: string, chainId: number) {
  const provider = await getEvmConnection(chainId);
  // Get transaction receipt
  let receipt = await provider.getTransactionReceipt(txHash);
  // Check if transaction receipt exists
  while (receipt === null) {
    // Wait for 3 seconds before checking again
    await new Promise((resolve) => setTimeout(resolve, 3000));
    receipt = await provider.getTransactionReceipt(txHash);
  }

  if (Number(receipt.status) !== 1) {
    throw new Error("Transaction failed");
  }

  return receipt;
}

export async function signEvmMessage(message: string) {
  const provider = await getEvmBrowserProvider();
  const signer = await provider.getSigner();
  return signer.signMessage(message);
}

// The above function (signEvmMessage) ends up on "Invalid Release Signature" for Meson
// Util for signing Meson Bridge Hash/Message that is an hex string
// Following guide https://meson.dev/tutorials/api
export async function signEvmHash(message_to_sign: string) {
  try {
    if (!window.ethereum) {
      throw new Error("MetaMask is not installed");
    }
    const ethereum = window.ethereum;
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });

    const hexEthHeader = utf8ToHex("\x19Ethereum Signed Message:\n52");
    const msg = message_to_sign.replace(hexEthHeader, "");

    const signature = await ethereum.request({
      method: "personal_sign",
      params: [msg, accounts[0]],
    });
    return signature;
  } catch (e: any) {
    if (e.code === 4001) {
      // MetaMask error code for user rejected request
      throw new Error("User rejected the signature request");
    }
    throw e;
  }
}

function utf8ToHex(utf8Str: string) {
  return Array.from(utf8Str)
    .map((char: any) => char.charCodeAt(0).toString(16).padStart(2, "0"))
    .join("");
}

export async function getEvmConnection(chainId: number) {
  const provider = await getEvmBrowserProvider();
  const network = await provider.getNetwork();
  if (network.chainId !== BigInt(chainId)) {
    await provider.send("wallet_switchEthereumChain", [
      { chainId: BigInt(chainId) },
    ]);
  }

  try {
    await provider.send("eth_requestAccounts", []);
  } catch (error) {
    throw new Error("User denied account access");
  }

  return provider;
}

async function getEvmBrowserProvider() {
  if (!window.ethereum) {
    throw new Error("MetaMask is not installed");
  }
  return new ethers.BrowserProvider(window.ethereum);
}

function adjustMaxFeePerGas(
  maxFeePerGas: BigNumberish,
  maxPriorityFeePerGas: BigNumberish
): BigNumberish {
  // Make sure maxFeePerGas is at least maxPriorityFeePerGas or the transaction will fail
  if (maxFeePerGas < maxPriorityFeePerGas) {
    maxFeePerGas = maxPriorityFeePerGas;
  }
  return maxFeePerGas;
}
export const parseAmount = (amount: string, decimals: string) =>
  ethers.formatUnits(amount, decimals);
