import { useState } from "react";
import {
  AssembledTransaction,
  getWalletTypeFromTransaction,
  useWallets,
} from "../../../hooks/wallets/use-wallets";
import { Submit } from "../../../hooks/use-chat";
import { AlertDialog } from "../../themed/alert-dialog";
import SphereIconLogoGreen from "../../../assets/images/SphereIconLogoGreen.png";
import { useAppDispatch } from "../../../redux/hooks";
import { updateTokenConfetti } from "../../../redux/slices/user";

interface TransferQuoteResponse {
  from_token: {
    address: string | null;
    decimals: number;
    symbol: string;
    name: string;
    logoURI: string;
  };
  from_chain: string;
  from_token_usd: number;
  from_address: string;
  to_address: string;
  estimated_time: number;
  from_amount: number;
  transactions: AssembledTransaction[];
}
export function TransferQuote({
  data,
  submit,
  active,
  onDone,
}: {
  data: TransferQuoteResponse;
  submit: (data: Submit) => void;
  onDone: () => void;
  active: boolean;
}) {
  const [transferCompleted, setTransferCompleted] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { signTransactions, addresses, agentsPreference } = useWallets();

  const dispatch = useAppDispatch();
  function onTransfer() {
    const walletType = getWalletTypeFromTransaction(data.transactions[0]);

    signTransactions(data.transactions, walletType).then((res) => {
      if (res.error) {
        if (res.error === "switching network") {
          onTransfer();
          return;
        }
        setError("Error: " + res.error);
        return;
      } else if (res.hashes.length !== data.transactions.length) {
        setError("Error: not all transactions were signed");
        return;
      }

      // avoid component to be reused
      setTransferCompleted(true);

      // submit the transaction to the server for analytics
      const walletAddress = addresses[walletType];
      if (!walletAddress) {
        setError("Error: Wallet not found");
        return;
      }

      if (res.hashes[res.hashes.length - 1]) {
        dispatch(
          updateTokenConfetti(data.from_token.logoURI || SphereIconLogoGreen)
        );
      }

      submit({
        transaction: data,
        signature: res.hashes[res.hashes.length - 1],
        walletAddress: walletAddress,
      });
    });
  }

  return (
    <div className="bg-gray-800 border border-gray-700 shadow-sm rounded-lg p-4 mt-4 flex flex-col items-center gap-4  max-w-[550px]">
      <div className="bg-gray-700 border border-gray-600 rounded-lg p-3 flex justify-between items-center gap-4 w-full h-70px">
        {/* Input Token */}
        <div className="flex flex-row items-start gap-2 w-1/3">
          <img
            src={data.from_token.logoURI}
            alt={data.from_token.symbol}
            className="w-6 h-6 rounded-full"
          />
          <div className="flex flex-col justify-center gap-1">
            <div className="space-x-2">
              <span className="text-white font-medium text-sm leading-6">
                {data.from_amount.toFixed(6)} {data.from_token.symbol}
              </span>
              <span className="text-white text-xs text-left">{data.from_chain}</span>
              {data.from_token_usd && (
                <span className="text-white text-xs text-left">
                  ${data.from_token_usd.toFixed(4)}
                </span>
              )}
            </div>

            <span className="text-white text-xs text-left">
              From: {shortenAddress(data.from_address)}
            </span>
            <span className="text-white text-xs text-left">
              To: {shortenAddress(data.to_address)}
            </span>
          </div>
        </div>
      </div>

      {/* Other details */}
      <div className="w-full">
        <div className="flex justify-between text-sm text-medium w-full text-white">
          <p>Estimated time:</p>
          <p>{data.estimated_time.toFixed(2)} min</p>
        </div>
      </div>

      {agentsPreference === true ? (
        <div className="w-full">
          <p>
            Confirm the action with the agent to get all executed as a batch
          </p>
        </div>
      ) : (
        <div className="text-left w-full space-x-2">
          <button
            onClick={onTransfer}
            disabled={transferCompleted}
            className="text-white text-sm bg-blue-500 hover:bg-blue-500/80 rounded-lg px-4 py-2 disabled:bg-blue-500/70 disabled:text-gray-300"
          >
            Execute
          </button>
          <button
            onClick={onDone}
            disabled={transferCompleted}
            className="text-white text-sm bg-blue-500 hover:bg-blue-500/80 rounded-lg px-4 py-2 disabled:bg-blue-500/70 disabled:text-gray-300"
          >
            Cancel
          </button>
        </div>
      )}
      {error && (
        <AlertDialog
          open={!!error}
          title={"Oops!"}
          description={error}
          actionLabel="Accept"
          onActionPress={() => setError("")}
        />
      )}
    </div>
  );
}

function shortenAddress(address: string) {
  return address.slice(0, 6) + "..." + address.slice(-6);
}
